crminfo = require('./crminfo.production').default

crminfo.apiHostSmsTool = 'https://nt-connect.dsh-agency.com'
crminfo.baseUrlWFH = 'https://connect.dsh-agency.com'
crminfo.showOfferSubscription = false;
crminfo.templateMessages = 'templateMessages.nt';

defaultConfig = require('./default-config')({
  domain: 'nt-connect.dsh-agency.com',
  protocol: 'https',
  sentry: true,
  updateNotification: true,
  crmUrl: 'https://a4.dsh-agency.com/api/v1/crminfo',
  crmUrlA5: 'https://api.dsh-agency.com/api/v3'
  GMTOffset: '+0300',
  crmPluginEnabled: true,
  oauthEnabled: true
  authApiHost: 'https://oauth.dsh-agency.com'
  authBackApiHost: 'https://auth-back.dsh-agency.com'
  fileServerUrl: 'https://nwzc6p9uv6.execute-api.eu-west-1.amazonaws.com/production_v1'
  mqttUrl: 'wss://nt-connect.dsh-agency.com:8084/mqtt'
  mqttSound: true
  mqttNotification: true
  crminfo: crminfo
  dashboardTags: false
  isProductionMode: true
  xmpp: false
  showRankingRules: true
})

module.exports = _.extend defaultConfig, {}
